<template>
  <b-sidebar
    id="sidebar-order-add-new-customer"
    :visible="isAddNewUserSidebarActive"
    sidebar-class="sidebar-lg"
    bg-variant="white"
    shadow
    backdrop
    no-header
    right
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Add Customer
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- Body -->
      <b-form
        class="p-2"
        @submit.prevent
      >

        <!-- Customer First Name -->
        <b-form-group
          label="Customer First Name"
          label-for="customer-name"
        >
          <b-form-input
            id="customer-first-name"
            v-model="customer.firstName"
            trim
            placeholder="John"
          />
        </b-form-group>

        <!-- Customer Last Name -->
        <b-form-group
          label="Customer Last Name"
          label-for="customer-last-name"
        >
          <b-form-input
            id="customer-last-name"
            v-model="customer.lastName"
            trim
            placeholder="Doe"
          />
        </b-form-group>

        <!-- Email -->
        <b-form-group
          label="Email"
          label-for="email"
        >
          <b-form-input
            id="email"
            v-model="customer.email"
            trim
            placeholder="example@domain.com"
            type="email"
          />
        </b-form-group>

        <!-- Address -->
        <b-form-group
          label="Address"
          label-for="address"
        >
          <b-form-input
            id="address"
            v-model="customer.address"
            placeholder="221B Baker Street"
            trim
          />
        </b-form-group>

        <!-- Address2 -->
        <b-form-group
          label="Address2"
          label-for="address2"
        >
          <b-form-input
            id="address2"
            v-model="customer.address2"
            placeholder="Unit 2"
            trim
          />
        </b-form-group>

        <!-- PostalCode -->
        <b-form-group
          label="Postal Code"
          label-for="postalCode"
        >
          <b-form-input
            id="postalCode"
            v-model="customer.postalCode"
            placeholder="A0A 0A0"
            trim
          />
        </b-form-group>

        <!-- City -->
        <b-form-group
          label="City"
          label-for="city"
        >
          <b-form-input
            id="city"
            v-model="customer.city"
            trim
            placeholder="Toronto"
            type="text"
          />
        </b-form-group>

        <!-- phoneNumber -->
        <b-form-group
          label="Phone Number"
          label-for="contact"
        >
          <b-form-input
            id="contact"
            v-model="customer.phoneNumber"
            type="number"
            placeholder="763-242-9206"
            trim
          />
        </b-form-group>

        <!-- Form Actions -->
        <div class="d-flex mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="submit"
            @click="onSubmit"
          >
            Add
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            @click="hide"
          >
            Cancel
          </b-button>
        </div>
      </b-form>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BButton,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import store from '@/store'

export default {
  components: {
    BSidebar, BForm, BFormGroup, BFormInput, BButton,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: false,
    },
  },
  setup(props, { emit }) {
    const customer = ref({
      externalId: '',
      role: 'client',
      plan: null,
      firstName: '',
      lastName: '',
      email: '',
      address: '',
      city: '',
      postalCode: '',
      country: 'Canada',
      province: 'Ontario',
      status: 'active',
    })

    const onSubmit = () => {
      store.dispatch('app-order/addClient', customer.value)
        .then(response => {
          emit('refetch-clients', response.data)
          emit('update:is-add-new-user-sidebar-active', false)
        })
    }

    return {
      customer,
      onSubmit,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
