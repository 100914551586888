<template>
  <b-row class="invoice-add">

    <!-- Col: Left (order Container) -->
    <b-col
      cols="12"
      xl="9"
      md="8"
    >
      <!-- Body -->
      <b-form>
        <b-card
          no-body
          class="invoice-preview-card"
        >
          <!-- Header -->
          <b-card-body class="invoice-padding pb-0">

            <div class="d-flex justify-content-between flex-md-row flex-column order-spacing mt-0">

              <!-- Header: Left Content -->
              <order-header-left-content />

              <!-- Header: Right Content -->
              <div class="invoice-number-date mt-md-0 mt-2">
                <div class="d-flex align-items-center">
                  <span class="title">
                    Service Date:
                  </span>
                  <flat-pickr
                    v-model="orderData.serviceDate"
                    class="form-control order-edit-input"
                    :config="{ enableTime: true, dateFormat: 'Y-m-d H:i'}"
                  />
                </div>
              </div>
            </div>
          </b-card-body>

          <!-- Spacer -->
          <hr class="invoice-spacing">

          <!-- order Client & Payment Details -->
          <b-card-body
            class="invoice-padding pt-0"
          >
            <b-row class="invoice-spacing">

              <!-- Col: order To -->
              <b-col
                cols="12"
                xl="6"
                class="mb-lg-1"
              >
                <h6 class="mb-2">
                  Customer Information:
                </h6>

                <!-- Select Client -->
                <v-select
                  v-model="orderData.client"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="clients"
                  :get-option-label="option => `${option.firstName} ${option.lastName}`"
                  required
                  input-id="order-data-client"
                  :clearable="false"
                  @search="fetchClients"
                >
                  <template #list-header>
                    <li
                      class="add-new-client-header d-flex align-items-center my-50"
                      @click="isAddNewUserSidebarActive = true"
                    >
                      <feather-icon
                        icon="PlusIcon"
                        size="16"
                      />
                      <span class="align-middle ml-25">Add New Client</span>
                    </li>
                  </template>
                </v-select>
                <order-sidebar-add-new-customer
                  :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
                  @refetch-clients="refetchClients"
                />

                <!-- Selected Client -->
                <div
                  v-if="orderData.client"
                  class="mt-1"
                >
                  <h6 class="mb-25">
                    {{ orderData.client.firstName }} {{ orderData.client.lastName }}
                  </h6>
                  <b-card-text class="mb-25">
                    {{ orderData.client.address }},  {{ orderData.client.city }} {{ orderData.client.postalCode }}
                  </b-card-text>
                  <b-card-text class="mb-25">
                    {{ orderData.client.phoneNumber }}
                  </b-card-text>
                  <b-card-text class="mb-0">
                    {{ orderData.client.email }}
                  </b-card-text>
                </div>
              </b-col>

              <!-- Col: Payment Details -->
              <b-col
                xl="6"
                cols="12"
                class="mt-xl-0 mt-2 justify-content-end d-xl-flex d-block"
              >
                <div>
                  <h6 class="mb-2">
                    Payment Details:
                  </h6>
                  <table>
                    <tbody>
                      <tr>
                        <td class="pr-1">
                          Total Due:
                        </td>
                        <td><span class="font-weight-bold">${{ orderData.paymentDetails.total.toFixed(2) }}</span></td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          Payment Method:
                        </td>
                        <td>
                          {{ orderData.paymentDetails.paymentMethod }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </b-col>
            </b-row>
          </b-card-body>

          <!-- Items Section -->
          <b-card-body class="invoice-padding form-item-section">
            <div
              ref="form"
              class="repeater-form"
              :style="{height: trHeight}"
            >
              <b-row
                v-for="(item, index) in orderData.items"
                :key="index"
                ref="row"
                class="pb-2"
              >

                <!-- Item Form -->
                <!-- ? This will be in loop => So consider below markup for single item -->
                <b-col cols="12">

                  <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                  <div class="d-none d-lg-flex">
                    <b-row class="flex-grow-1 px-1">
                      <!-- Single Item Form Headers -->
                      <b-col
                        cols="6"
                        lg="3"
                      >
                        Item
                      </b-col>
                      <b-col
                        cols="6"
                        lg="3"
                      >
                        Model
                      </b-col>
                      <b-col
                        cols="6"
                        lg="3"
                      >
                        Description
                      </b-col>
                      <b-col
                        cols="4"
                        lg="2"
                      >
                        Price
                      </b-col>
                      <b-col
                        cols="3"
                        lg="1"
                      >
                        Qty
                      </b-col>
                    </b-row>
                    <div class="form-item-action-col" />
                  </div>

                  <!-- Form Input Fields OR content inside bordered area  -->
                  <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                  <div class="d-flex border rounded">
                    <b-row class="flex-grow-1 p-2">
                      <!-- Single Item Form Headers -->
                      <b-col
                        cols="12"
                        lg="3"
                      >
                        <label class="d-inline d-lg-none">Item</label>
                        <b-form-input
                          v-model="item.notes"
                          type="text"
                          class="mb-2"
                        />
                      </b-col>
                      <b-col
                        cols="6"
                        lg="3"
                      >
                        <label class="d-inline d-lg-none">Model</label>
                        <v-select
                          v-model="item.product"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="items"
                          label="displayName"
                          :clearable="false"
                          class="mb-2 item-selector-title"
                          placeholder="Select Item"
                          @input="val => updateItemForm(index, val)"
                        >
                          <template #list-header>
                            <li
                              class="add-new-client-header d-flex align-items-center my-50"
                              @click="isAddNewItemSidebarActive = true"
                            >
                              <feather-icon
                                icon="PlusIcon"
                                size="16"
                              />
                              <span class="align-middle ml-25">Add New Product</span>
                            </li>
                          </template>
                        </v-select>
                        <order-sidebar-add-new-item
                          :is-add-new-item-sidebar-active.sync="isAddNewItemSidebarActive"
                          @refetch-items="refetchItems"
                        />
                      </b-col>
                      <b-col
                        cols="12"
                        lg="3"
                      >
                        <label class="d-inline d-lg-none">Description</label>
                        <b-form-input
                          v-model="item.description"
                          type="text"
                          class="mb-2"
                        />
                      </b-col>
                      <b-col
                        cols="4"
                        lg="2"
                      >
                        <label class="d-inline d-lg-none">Price</label>
                        <b-form-input
                          v-model="item.price"
                          type="number"
                          class="mb-1"
                          @input="updateSubtotal()"
                        />
                      </b-col>
                      <b-col
                        cols="3"
                        lg="1"
                      >
                        <label class="d-inline d-lg-none">Qty</label>
                        <b-form-input
                          v-model="item.quantity"
                          type="number"
                          class="mb-1"
                          @input="updateSubtotal()"
                        />
                      </b-col>
                    </b-row>
                    <div class="d-flex flex-column justify-content-between border-left py-50 px-25">
                      <feather-icon
                        size="16"
                        icon="XIcon"
                        class="cursor-pointer"
                        @click="removeItem(index)"
                      />
                      <feather-icon
                        :id="`form-item-settings-icon-${index}`"
                        size="16"
                        icon="SettingsIcon"
                        class="cursor-pointer"
                      />

                      <!-- Setting Item Form -->
                      <b-popover
                        :ref="`form-item-settings-popover-${index}`"
                        :target="`form-item-settings-icon-${index}`"
                        triggers="click"
                        placement="lefttop"
                      >
                        <b-form>
                          <b-row>

                            <!-- Field: Discount -->
                            <b-col cols="12">
                              <b-form-group
                                label="Discount(%)"
                                :label-for="`setting-item-${index}-discount`"
                              >
                                <b-form-input
                                  :id="`setting-item-${index}-discount`"
                                  :value="null"
                                  type="number"
                                />
                              </b-form-group>
                            </b-col>

                            <!-- Field: Tax 1 -->
                            <b-col cols="6">
                              <b-form-group
                                label="Tax 1"
                                :label-for="`setting-item-${index}-tax-1`"
                              >
                                <v-select
                                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                  :value="'10%'"
                                  :options="['0%', '1%', '10%', '14%', '18%']"
                                  :input-id="`setting-item-${index}-tax-1`"
                                  :clearable="false"
                                />
                              </b-form-group>
                            </b-col>

                            <!-- Field: Tax 2 -->
                            <b-col cols="6">
                              <b-form-group
                                label="Tax 2"
                                :label-for="`setting-item-${index}-tax-2`"
                              >
                                <v-select
                                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                  :value="'10%'"
                                  :options="['0%', '1%', '10%', '14%', '18%']"
                                  :input-id="`setting-item-${index}-tax-2`"
                                  :clearable="false"
                                />
                              </b-form-group>
                            </b-col>

                            <b-col
                              cols="12"
                              class="d-flex justify-content-between mt-1"
                            >
                              <b-button
                                variant="outline-primary"
                                @click="() => {$refs[`form-item-settings-popover-${index}`][0].$emit('close')}"
                              >
                                Apply
                              </b-button>
                              <b-button
                                variant="outline-secondary"
                                @click="() => {$refs[`form-item-settings-popover-${index}`][0].$emit('close')}"
                              >
                                Cancel
                              </b-button>
                            </b-col>
                          </b-row>
                        </b-form>
                      </b-popover>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </div>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              size="sm"
              variant="primary"
              @click="addNewItemInItemForm"
            >
              Add Item
            </b-button>
          </b-card-body>

          <!-- order Description: Total -->
          <b-card-body class="invoice-padding pb-0">
            <b-row>

              <!-- Col: Salesperson -->
              <b-col
                cols="12"
                md="6"
                class="mt-md-0 mt-3 d-flex align-items-center"
                order="2"
                order-md="1"
              >
                <label
                  for="order-data-sales-person"
                  class="text-nowrap mr-50"
                >Salesperson:</label>
                <v-select
                  id="order-data-sales-person"
                  v-model="orderData.salesperson"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="salespersons"
                  :get-option-label="option => `${option.firstName} ${option.lastName}`"
                  input-id="order-data-sales-person"
                  :clearable="false"
                />
              </b-col>

              <!-- Col: Total -->
              <b-col
                cols="12"
                md="6"
                class="mt-md-6 d-flex justify-content-end"
                order="1"
                order-md="2"
              >
                <div class="invoice-total-wrapper">
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      Subtotal:
                    </p>
                    <p class="invoice-total-amount">
                      ${{ (orderData.paymentDetails.subtotal || 0).toFixed(2) }}
                    </p>
                  </div>
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      Discount:
                    </p>
                    <p class="invoice-total-amount">
                      ${{ (orderData.paymentDetails.less || 0).toFixed(2) }}
                    </p>
                  </div>
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      Tax:
                    </p>
                    <p class="invoice-total-amount">
                      ${{ ((orderData.paymentDetails.subtotal || 0) * (orderData.paymentDetails.taxRate || 0.13)).toFixed(2) }}
                    </p>
                  </div>
                  <hr class="my-50">
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      Total:
                    </p>
                    <p class="invoice-total-amount">
                      ${{ ((orderData.paymentDetails.subtotal || 0) * (1 + (orderData.paymentDetails.taxRate || 0.13))).toFixed(2) }}
                    </p>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-card-body>

          <!-- Spacer -->
          <hr class="invoice-spacing">

          <!-- Note -->
          <b-card-body class="invoice-padding pt-0">
            <span class="font-weight-bold">Notes: </span>
            <quill-editor
              v-model="orderData.notes"
            />
          </b-card-body>

          <!-- Agreement -->
          <b-card-body
            v-show="orderData.signature.isRequired"
            class="invoice-padding pt-0"
          >
            <h5>
              Agreement
            </h5>
            <v-select
              id="order-signature-agreementLinks"
              v-model="orderData.signature.agreementLinks[0]"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="agreementLinks"
              :get-option-label="option => `${option.title}`"
              input-id="order-signature-agreementLinks"
              :clearable="false"
            />
          </b-card-body>
        </b-card>
      </b-form>
    </b-col>

    <!-- Right Col: Card -->
    <b-col
      cols="12"
      md="4"
      xl="3"
      class="invoice-actions mt-md-0 mt-2"
    >

      <!-- Action Buttons -->
      <b-card>

        <!-- Button: Send order -->
        <b-button
          v-if="false"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="mb-75"
          block
          disabled
          @click="sendOrder()"
        >
          Send order
        </b-button>

        <!-- Button: Download -->
        <b-button
          v-if="false"
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary"
          class="mb-75"
          block
          @click="previewOrder()"
        >
          Preview
        </b-button>

        <!-- Button: Print -->
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary"
          block
          @click="upsertOrder(orderData)"
        >
          Save
        </b-button>
      </b-card>

      <div class="mt-2">
        <b-form-group
          label="Type"
          label-for="order-type"
        >
          <v-select
            v-model="orderData.type"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="typeOptions"
            input-id="order-type"
            class="payment-selector"
            :clearable="false"
          />
        </b-form-group>
      </div>

      <div class="mt-2">
        <b-form-group
          label="Status"
          label-for="order-status"
        >
          <v-select
            v-model="orderData.status"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="statusOptions"
            input-id="order-status"
            class="payment-selector"
            :clearable="false"
          />
        </b-form-group>
      </div>

      <div class="mt-2">
        <b-form-group
          label="Service Person"
          label-for="order-service-person"
        />
        <b-form-input
          v-model="orderData.servicePerson"
          input-id="order-service-person"
          type="text"
          class="mb-1"
        />
      </div>

      <div class="mt-2">
        <b-form-group
          label="Referral Source"
          label-for="order-referral-source"
        />
        <b-form-input
          v-model="orderData.referralSource"
          input-id="order-referral-source"
          type="text"
          class="mb-1"
        />
      </div>

      <!-- Payment Method -->
      <div class="mt-2">
        <b-form-group
          label="Accept Payment Via"
          label-for="payment-method"
        >
          <v-select
            v-model="orderData.paymentDetails.paymentMethod"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="paymentMethods"
            input-id="payment-method"
            class="payment-selector"
            :clearable="false"
          />
        </b-form-group>

        <!-- Signature -->
        <div class="d-flex justify-content-between align-items-center">
          <label>Signature Required</label>
          <b-form-checkbox
            id="signatureRequired"
            v-model="orderData.signature.isRequired"
            switch
          />
        </div>
      </div>
    </b-col>
  </b-row>

</template>

<script>
import {
  BRow, BCol, BCard, BCardBody, BButton, BCardText, BForm, BFormCheckbox, BFormGroup, BFormInput, BPopover, VBToggle,
} from 'bootstrap-vue'
import { quillEditor } from 'vue-quill-editor'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import OrderHeaderLeftContent from '@/views/apps/order/OrderHeaderLeftContent.vue'
import Ripple from 'vue-ripple-directive'
import { heightTransition } from '@core/mixins/ui/transition'
import useOrderHandler from '@/views/apps/order/useOrderHandler'
import store from '@/store'
import orderStoreModule from '@/views/apps/order/OrderStoreModule'
import { onUnmounted, ref } from '@vue/composition-api'
import router from '@/router'
// eslint-disable-next-line no-unused-vars
import { $api } from '@apiConfig'
import OrderSidebarAddNewCustomer from '@/views/apps/order/OrderSidebarAddNewCustomer.vue'
import OrderSidebarAddNewItem from '@/views/apps/order/OrderSidebarAddNewItem.vue'

export default {
  components: {
    OrderSidebarAddNewCustomer,
    OrderSidebarAddNewItem,
    quillEditor,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BFormCheckbox,
    BForm,
    BFormGroup,
    BFormInput,
    BPopover,
    flatPickr,
    vSelect,
    OrderHeaderLeftContent,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,

  },
  mixins: [heightTransition],
  watch: {
    // eslint-disable-next-line func-names
    'orderData.items.length': function () {
      this.initTrHeight()
    },
  },
  mounted() {
    this.initTrHeight()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    addNewItemInItemForm() {
      this.$refs.form.style.overflow = 'hidden'
      this.orderData.items.push(JSON.parse(JSON.stringify(this.itemFormBlankItem)))

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
        setTimeout(() => {
          this.$refs.form.style.overflow = null
        }, 350)
      })
    },
    removeItem(index) {
      this.orderData.items.splice(index, 1)
      this.updateSubtotal()
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
  },
  setup() {
    const {
      // eslint-disable-next-line no-unused-vars
      validateOrder,
      decoratePaymentDetails,
    } = useOrderHandler()

    const INVOICE_APP_STORE_MODULE_NAME = 'app-order'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, orderStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    const salespersons = ref([])
    store.dispatch('app-order/fetchSalespersons')
      .then(response => { salespersons.value = response.data })

    const agreementLinks = ref([])
    store.dispatch('app-order/fetchAgreementLinks')
      .then(response => { agreementLinks.value = response.data })

    const items = ref([])
    store.dispatch('app-ecommerce/fetchProducts', {
      _limit: 1000,
    })
      .then(response => { items.value = response.data })

    const itemFormBlankItem = {
      item: null,
      price: 0,
      quantity: 0,
      description: '',
    }

    const now = new Date()
    const orderData = ref({
      client: null,
      // ? Set single Item in form for adding data
      items: [JSON.parse(JSON.stringify(itemFormBlankItem))],
      serviceDate: now,
      servicePerson: '',
      referralSource: '',
      // eslint-disable-next-line radix
      referenceNumber: Math.trunc(+now / 1000).toString(), // generate a unique reference number
      issuedDate: now,
      salesperson: null,
      notes: 'Thank You!',
      status: 'created',
      type: 'order',
      paymentDetails: {
        paymentMethod: 'Cash',
        subtotal: 0,
        balance: 0,
        taxRate: 0.13,
        tax: 0,
        total: 0,
        less: 0,
        deposit: 0,
      },
      signature: {
        isRequired: false,
        isSigned: false,
        agreementLinks: [{
          title: '',
          url: '',
        }],
      },
    })

    const clients = ref([])
    const fetchClients = search => {
      const payload = {
        _sort: 'updatedAt:DESC',
        _where: {
          role: 'client',
          _or: [],
        },
      }

      if (search) {
        const fullTextSearchFields = ['firstName', 'lastName', 'fullName', 'phoneNumber', 'email', 'referenceNumber', 'externalId']
        fullTextSearchFields.forEach(field => {
          const obj = {}
          obj[`${field}_contains`] = search
          // eslint-disable-next-line no-underscore-dangle
          payload._where._or.push(obj)
        })
      }

      store.dispatch('app-order/fetchClients', {
        ...payload,
        _limit: 10,
      })
        .then(response => { clients.value = response.data })
    }
    fetchClients()

    const isAddNewUserSidebarActive = ref(false)
    const isAddNewItemSidebarActive = ref(false)

    const refetchClients = client => {
      clients.value.push(client)
      orderData.value.client = client
    }

    const refetchItems = item => {
      items.value.splice(0, 0, item)
    }

    const updateSubtotal = () => {
      let subtotal = 0
      orderData.value.items.forEach(item => {
        // eslint-disable-next-line radix
        subtotal += item.price * item.quantity
      })
      orderData.value.paymentDetails.subtotal = subtotal
      orderData.value.paymentDetails.total = orderData.value.paymentDetails.subtotal * 1.13
      orderData.value.paymentDetails = decoratePaymentDetails(orderData.value.paymentDetails)
    }

    const updateItemForm = (index, val) => {
      const {
        item, price, quantity, description, thumbnail,
      } = val

      orderData.value.items[index].thumbnail = thumbnail
      orderData.value.items[index].item = item
      orderData.value.items[index].price = price
      orderData.value.items[index].description = description
      orderData.value.items[index].quantity = quantity || 1

      updateSubtotal()
    }

    const upsertOrder = () => {
      try {
        const orderDataValue = orderData.value
        const agreementLinksFormatted = []

        orderDataValue.signature.agreementLinks.forEach(agreementLink => {
          try {
            const body = {
              title: agreementLink.title,
              url: `${$api.storage.objectBaseUrl}${agreementLink.attachment[0].url}`,
              signed: false,
              metadata: agreementLink,
            }
            agreementLinksFormatted.push(body)
          } catch (e) {
            console.log(JSON.stringify(e))
          }
        })
        orderDataValue.signature.agreementLinks = agreementLinksFormatted
        const validationResult = validateOrder(orderDataValue)
        if (!validationResult.valid) {
          window.alert('validation failed, please check the errors')
          return
        }
        store.dispatch('app-order/upsertOrder', orderDataValue)
          .then(response => {
            router.push({ name: 'apps-order-preview', params: { id: response.data.id } })
          })
          .catch(e => {
            console.log(e.message)
            window.alert('error in upserting order data, please contact the administrator')
          })
      } catch (exception) {
        window.alert('error in upserting order data, please contact the administrator')
      }
    }

    if (router.currentRoute.params.id) {
      store.dispatch('app-order/fetchOrder', { id: router.currentRoute.params.id })
        .then(response => {
          /* eslint-disable no-param-reassign */
          response.data.paymentDetails = decoratePaymentDetails(response.data.paymentDetails)
          /* eslint-enable no-param-reassign */
          orderData.value = response.data
        })
        .catch(() => {
          orderData.value = undefined
        })
    }

    const paymentMethods = ['Cash', 'Cheque', 'Credit', 'EMT', 'Other']

    const statusOptions = [
      'created',
      'quoted',
      'contracted',
      'cancelled',
      'refunded',
      'pending',
      'completed',
    ]

    const typeOptions = [
      'order',
      'quotation',
      'contract',
      'other',
    ]

    return {
      isAddNewUserSidebarActive,
      isAddNewItemSidebarActive,
      orderData,
      clients,
      fetchClients,
      refetchClients,
      refetchItems,
      salespersons,
      agreementLinks,
      items,
      upsertOrder,
      updateItemForm,
      updateSubtotal,
      itemFormBlankItem,
      paymentMethods,
      statusOptions,
      typeOptions,
    }
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-order.scss";
@import '~@core/scss/base/components/variables-dark';

.form-item-section {
  background-color:$product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: .35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }

  }
}
</style>
