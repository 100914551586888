<template>
  <section class="invoice-add-wrapper">
    <order-form />

    <order-sidebar-send-order />
    <order-sidebar-add-payment />
  </section>
</template>

<script>
import { ref, onUnmounted } from '@vue/composition-api'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import {
  VBToggle,
} from 'bootstrap-vue'

import OrderForm from '@/views/apps/order/OrderForm.vue'
import OrderStoreModule from '../OrderStoreModule'
import OrderSidebarSendOrder from '../OrderSidebarSendOrder.vue'
import OrderSidebarAddPayment from '../OrderSidebarAddPayment.vue'
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'

export default {
  components: {
    OrderForm,
    OrderSidebarSendOrder,
    OrderSidebarAddPayment,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  mixins: [heightTransition],
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-order'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, OrderStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    const clients = ref([])
    store.dispatch('app-order/fetchClients')
      .then(response => { clients.value = response.data })

    const salespersons = ref([])
    store.dispatch('app-order/fetchSalespersons')
      .then(response => { salespersons.value = response.data })

    const items = ref([])
    store.dispatch('app-ecommerce/fetchProducts')
      .then(response => { items.value = response.data })

    const itemFormBlankItem = {
      item: null,
      price: 0,
      quantity: 0,
      description: '',
    }

    return {
      clients,
      salespersons,
      items,
      itemFormBlankItem,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-order.scss";

.form-item-section {
background-color:$product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: .35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }

  }
}
</style>
