<template>
  <b-sidebar
    id="sidebar-order-add-new-item"
    :visible="isAddNewItemSidebarActive"
    sidebar-class="sidebar-lg"
    bg-variant="white"
    shadow
    backdrop
    no-header
    right
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Add Product
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- Body -->
      <b-form
        class="p-2"
        @submit.prevent
      >

        <!-- Display Name -->
        <b-form-group
          label="Display Name"
          label-for="product-display-name"
        >
          <b-form-input
            id="product-display-name"
            v-model="product.displayName"
            trim
            placeholder=""
          />
        </b-form-group>
        <!-- Display Name -->
        <b-form-group
          label="External Id"
          label-for="product-externalId"
        >
          <b-form-input
            id="product-externalId"
            v-model="product.externalId"
            trim
            placeholder=""
          />
        </b-form-group>

        <!-- Display Name -->
        <b-form-group
          label="Dscription"
          label-for="product-description"
        >
          <b-form-input
            id="product-description"
            v-model="product.description"
            trim
            placeholder=""
          />
        </b-form-group>

        <!-- Display Name -->
        <b-form-group
          label="SKU"
          label-for="product-SKU"
        >
          <b-form-input
            id="product-SKU"
            v-model="product.sku"
            trim
            placeholder=""
          />
        </b-form-group>

        <!-- Display Name -->
        <b-form-group
          label="Provider"
          label-for="product-provider"
        >
          <b-form-input
            id="product-provider"
            v-model="product.provider"
            trim
            placeholder="Samsung"
          />
        </b-form-group>

        <!-- Display Name -->
        <b-form-group
          label="Price"
          label-for="product-price"
        >
          <b-form-input
            id="product-price"
            v-model="product.price"
            trim
            placeholder="0"
          />
        </b-form-group>

        <!-- Form Actions -->
        <div class="d-flex mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="submit"
            @click="onSubmit"
          >
            Add
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            @click="hide"
          >
            Cancel
          </b-button>
        </div>
      </b-form>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormTextarea, BButton,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import store from '@/store'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    BSidebar, BForm, BFormGroup, BFormInput, BFormTextarea, BButton,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewItemSidebarActive',
    event: 'update:is-add-new-item-sidebar-active',
  },
  props: {
    isAddNewItemSidebarActive: {
      type: Boolean,
      required: false,
    },
  },
  setup(props, { emit }) {
    const product = ref({
      externalId: '',
      displayName: '',
      description: '',
      sku: '',
      provider: '',
      price: 0.0,
    })

    const onSubmit = () => {
      store.dispatch('app-order/addProduct', product.value)
        .then(response => {
          emit('refetch-items', response.data)
          emit('update:is-add-new-item-sidebar-active', false)
        })
    }

    return {
      product,
      onSubmit,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
